import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLoaderData, Link, useSearchParams } from "@remix-run/react";
import { Money, Image } from "@shopify/hydrogen";

export function meta() {
  return [{ title: "lazovski.uk" }, { description: "Headwear" }];
}

export async function loader({ params, context }) {

  return await context.storefront.query(PRODUCTS_QUERY);
}

export default function Index() {
  const [searchParams] = useSearchParams();
  const { register, handleSubmit } = useForm();
  const [data, setData] = useState({
    search: searchParams.get('search') ?? "",
    color: searchParams.get('color') ?? "",
    minPrice: searchParams.get('minPrice') ?? "",
    maxPrice: searchParams.get('maxPrice') ?? ""
  });
  const [isVisible, setIsVisible] = useState(false);
  const { products } = useLoaderData();

  const transformedProducts = products.nodes
    .map((item) => {
      return {
        ...item,
        price: Number(item.variants.nodes[0].price.amount)
      };
    })
    .filter((item) => {
      if (data) {
        if (data.color) {
          return item.tags.includes(data.color);
        }

        if (data.search) {
          return item.tags.includes(data.search.toLowerCase());
        }

        if (data.maxPrice) {
          return item.price <= Number(data.maxPrice);
        }

        if (data.minPrice) {
          return item.price >= Number(data.minPrice);
        }

        return item;
      }
    })
    .sort((a, b) => {
      if (data) {
        if (data.sort === "ABC-") {
          return b.title.localeCompare(a.title);
        } else if (data.sort === "ABC+") {
          return a.title.localeCompare(b.title);
        } else if (data.sort === "PRICE+") {
          return a.price - b.price;
        } else if (data.sort === "PRICE-") {
          return b.price - a.price;
        } else if (data.sort === "DATE+") {
          return a.createdAt.localeCompare(b.createdAt);
        } else if (data.sort === "DATE-") {
          return b.createdAt.localeCompare(a.createdAt);
        }
      }
    });

  const maxPrice = Math.max(...transformedProducts.map((item) => item.price));

  const onSubmit = (data) => {
    setData(data);
  };

  const resetFilters = () => {
    setData({
      search: "",
      color: "",
      minPrice: "",
      maxPrice: "",
    });

  };

  const toggleFilters = () => {
    setIsVisible(!isVisible);
  };

  return (

    <section className="leading-6 text-black">
      <div
        className="pb-16 px-6 mx-auto w-full text-black lg:max-w-screen-lg xl:max-w-screen-xl sm:max-w-screen-sm sm:pb-20 md:max-w-screen-md">
        <h2 className="border-gray-200 border-solid sr-only">Products</h2>
        <div
          className="flex justify-center py-0 px-6 my-10 mx-auto w-64 tracking-wide leading-7 break-words lg:max-w-screen-lg xl:max-w-screen-xl sm:max-w-screen-sm md:w-full md:max-w-screen-md md:px-20 md:py-0 text-zinc-600">
          <div className="w-full text-center">
            <h2 className="m-0 text-2xl md:text-3xl md:leading-9 text-neutral-800 leading-[52px]">
              Browse our latest products
            </h2>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center md:flex-row lg:justify-end">
          <form onChange={handleSubmit(onSubmit)}>
            <p className="mr-4">
              <span className="lg:mb-2 lg:-ml-8 block flex items-center justify-center md:inline mr-4">Sort by:</span>
              <select {...register("sort")}>
                <option value="ABC+">Alphabetically, from A-Z</option>
                <option value="ABC-">Alphabetically, from Z-A</option>
                <option value="PRICE+">Price, low to high</option>
                <option value="PRICE-">Price, high to low</option>
                <option value="DATE+">Date, old to new</option>
                <option value="DATE-">Date, new to old</option>
              </select>
            </p>
          </form>
          <p className="hidden md:flex">
            {transformedProducts.length} products
          </p>
        </div>
        <div className="flex flex-col items-center justify-center mt-10 lg:-mt-8 md:flex-row lg:justify-start inline-flex w-full md:w-fit justify-center rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2">
          <button onClick={() => (setIsVisible(!isVisible))}>
            {isVisible ? 'Hide' : 'Show'} Filters
          </button>
        </div>
      </div>

      <div className="grid gap-12 mx-auto w-5/6 lg:grid-cols-4 grid-cols-2">
        {isVisible && (
          <div
            className="leading-6 bg-white rounded-sm border border-solid border-slate-200 text-slate-500"
            style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px" }}
          >
            <div className="flex flex-col gap-5 p-6 border-0 border-current border-solid">
              <div className="border-0 border-current border-solid">
                <label className="block mb-3 text-sm font-medium leading-5 text-gray-800 border-0 border-current border-solid cursor-default">
                  Search
                </label>
                <form onChange={handleSubmit(onSubmit)}>
                  <input {...register('search')} value={data.search} placeholder="Search for products..." className="py-3 px-5 mt-2 mb-4 w-full font-medium rounded-lg border border-gray-800
                  border-solid duration-150 ease-in-out bg-white outline-offset-2"/>
                  <label className="block mb-3 text-sm font-medium leading-5 text-gray-800 border-0 border-current border-solid cursor-default">
                    Color
                  </label>
                  <select {...register("color")} value={data.color} className="py-3 px-5 mt-2 mb-4 w-full font-medium rounded-lg border border-gray-800
                  border-solid duration-150 ease-in-out bg-white outline-offset-2">
                    <option value="" className="uppercase">Select a color</option>
                    <option value="beige" className="uppercase">
                      Beige
                    </option>
                    <option value="black" className="uppercase">
                      Black
                    </option>
                    <option value="blue" className="uppercase">
                      Blue
                    </option>
                    <option value="bronze" className="uppercase">
                      Bronze
                    </option>
                    <option value="gold" className="uppercase">
                      Gold
                    </option>
                    <option value="gray" className="uppercase">
                      Gray
                    </option>
                    <option value="lime" className="uppercase">
                      Lime
                    </option>
                    <option value="mustard" className="uppercase">
                      Mustard
                    </option>
                    <option value="orange" className="uppercase">
                      Orange
                    </option>
                    <option value="pink" className="uppercase">
                      Pink
                    </option>
                    <option value="purple" className="uppercase">
                      Purple
                    </option>
                    <option value="red" className="uppercase">
                      Red
                    </option>
                    <option value="white" className="uppercase">
                      White
                    </option>
                    <option value="yellow" className="uppercase">
                      Yellow
                    </option>
                  </select>
                  <label className="block mb-3 text-sm font-medium leading-5 text-gray-800 border-0 border-current border-solid cursor-default">
                    Price
                  </label>
                  <input type="number" {...register('minPrice')} value={data.minPrice} placeholder="From:" className="py-3 px-5 mt-2 mb-4 w-full font-medium rounded-lg border border-gray-800
                  border-solid duration-150 ease-in-out bg-white outline-offset-2
"/>
                  <input type="number" {...register('maxPrice')} value={data.maxPrice} placeholder="To:" className="py-3 px-5 mt-2 mb-4 w-full font-medium rounded-lg border border-gray-800
                  border-solid duration-150 ease-in-out bg-white outline-offset-2
"/>
                  <button type="submit" className="py-3 px-6 m-0 w-full font-medium text-center text-white normal-case bg-black
                  bg-none rounded-md cursor-pointer
">Apply filters</button>
                  <button onClick={() => resetFilters()} className="py-3 px-6 mt-2 w-full font-medium text-center text-black normal-case
                  bg-white rounded-md cursor-pointer border-2 border-gray-800
">Reset filters</button>
                </form>
              </div>
            </div>
          </div>
        )}

        {transformedProducts.map((product) => {
          return (
            <Link
              to={`/products/${product.handle}`}
              key={product.id}
              className="overflow-hidden rounded-lg border-0 border-orange-100 border-solid cursor-pointer"
            >
              <div className="relative">
                <Image
                  data={product.featuredImage}
                  alt={product.title}
                  className="block max-w-full h-auto align-middle aspect-square"
                  width={product.featuredImage.width}
                  height={product.featuredImage.height}
                  loading="eager"
                />
              </div>
              <div className="flex flex-col py-6 px-5 text-zinc-700">
                <h3 className="m-0">{product.title}</h3>
                <div className="mt-5 text-lg font-bold leading-7 justify-self-end">
                  <span className="">
                    <Money
                      withoutTrailingZeros
                      data={product.variants.nodes[0].price}
                    />
                  </span>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </section>
  );
}

const PRODUCTS_QUERY = `#graphql
  query FeaturedProducts {
  products(first: 20, sortKey: TITLE) {
    nodes {
      id
      title
      handle
      tags
      createdAt
      featuredImage {
        url
        altText
        height
        width
      }
      variants(first: 1) {
        nodes {
          id
          price {
            amount
            currencyCode
          }
          compareAtPrice {
            amount
            currencyCode
          }
        }
      }
    }
  }
}
`;
